(function ($) {
  Drupal.behaviors.tabbedProductsBlockV1 = {
    attach: function (context) {
      var $modules = $('.js-tabbed-products-block-v1', context);
      var $grids = $('.js-grid--mpp', $modules);
      var $carouselGrids = $grids.filter('.js-grid-mpp--carousel');
      var $carousels = $('.js-grid-mpp-items--carousel', $carouselGrids);

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-tabbed-products-block-trigger', $module);
        var $content = $('.js-tabbed-products-block-content', $module);

        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (event) {
          event.preventDefault();
          var index = $(this).index();

          switchToIndex(index);
        });

        function switchToIndex(index) {
          var $activeContent = $content.eq(index);
          var $grid = $('.js-mpp-product-layout', $activeContent);

          // Show this tab content
          $content.removeClass('current');
          $activeContent.addClass('current');
          $trigger.removeClass('current');
          $trigger.eq(index).addClass('current');

          // Detect if we have a product grid and if so recalculate the grid
          if ($grid.length) {
            $(document).trigger('grid.reflow');
            $(window)
              .off('resize.tabbedBlock')
              .on(
                'resize.tabbedBlock',
                _.debounce(function () {
                  $(document).trigger('grid.reflow');
                }, 150)
              );
          }
        }
      });
      $carousels.each(function () {
        var $carousel = $(this);
        var $arrowsDiv = $carousel.parent().find('.carousel-controls');
        var $dotsDiv = $carousel.parent().find('.carousel-dots');
        var slides_to_show = JSON.parse($carousel.closest('.js-grid--mpp').attr('data-grid-items-per-row'));
        var $carouselItem = $('.js-grid--mpp__item', $carousel);
        var slidesTotal = $carousel.find('.js-grid--mpp__item').length;
        var slideCount = slidesTotal > 4 ? 4 : 3;

        var settings = {
          appendArrows: $arrowsDiv,
          infinite: true,
          slidesToShow: slideCount,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: '<button type="button" class="slick-prev"></button>',
          nextArrow: '<button type="button" class="slick-next"></button>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: $dotsDiv,
                dots: false,
                slidesToShow: slides_to_show.medium,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                appendDots: $dotsDiv,
                dots: false,
                slidesToShow: slides_to_show.small,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $carousel.data('slides-to-show');

        if (!slides_to_show.large) {
          slides_to_show.large = slides_to_show.medium;
        }
        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $carousel.slick(settings);
        $carousel.data('grid-items-count', $carouselItem.length);
      });
    }
  };
})(jQuery);
